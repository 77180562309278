
import { IconBrandChrome, IconHelp, IconBooks } from '@tabler/icons';
import QueryStatsIcon from '@mui/icons-material/QueryStats';
import EmojiObjectsIcon from '@mui/icons-material/EmojiObjects';
import TextRotationNoneIcon from '@mui/icons-material/TextRotationNone';
import GradeIcon from '@mui/icons-material/Grade';
import MicIcon from '@mui/icons-material/Mic';
import HikingIcon from '@mui/icons-material/Hiking';

// ==============================|| DASHBOARD MENU ITEMS ||============================== //
// constant
const icons = { IconBrandChrome, IconHelp, IconBooks, EmojiObjectsIcon, QueryStatsIcon, TextRotationNoneIcon, GradeIcon, MicIcon };

const training = {
  id: 'sample-docs-trainig',
  type: 'group',
  children: [
    {
      id: 'sample-docs-training',
      type: 'item',
      title: 'Percorso',
      url: '/percorso',
      breadcrumbs: false,
      icon: HikingIcon
    },
    {
      id: 'rules',
      title: 'Teoria',
      type: 'collapse',
      icon: icons.IconBooks,
      breadcrumbs: false,
      target: false,
      children: [
          {
            id: 'eaperta',
            title: 'E aperta',
            type: 'item',
            url: '/e-aperta',
            breadcrumbs: false
        },
        {
          id: 'echiusa',
          title: 'E chiusa',
          type: 'item',
          url: '/e-chiusa',
          breadcrumbs: false
        },
        {
          id: 'oaperta',
          title: 'O aperta',
          type: 'item',
          url: '/o-aperta',
          breadcrumbs: false
      },
      {
        id: 'ochiusa',
        title: 'O chiusa',
        type: 'item',
        url: '/o-chiusa',
        breadcrumbs: false
    },
    {
      id: 'ssorda',
      title: 'S sorda',
      type: 'item',
      url: '/s-sorda',
      breadcrumbs: false
    },
    {
      id: 'ssonora',
      title: 'S sonora',
      type: 'item',
      url: '/s-sonora',
      breadcrumbs: false
    },
    {
      id: 'zsorda',
      title: 'Z sorda',
      type: 'item',
      url: '/z-sorda',
      breadcrumbs: false
    },
    {
      id: 'zsonora',
      title: 'Z sonora',
      type: 'item',
      url: '/z-sonora',
      breadcrumbs: false
    }
      ]
  },
    {
      id: 'quiz',
      title: 'Esercizi',
      type: 'collapse',
      url: '/quiz',
      icon: icons.EmojiObjectsIcon,
      breadcrumbs: false,
      target: false,
      children: [
        {
          id: 'allenamente',
          title: 'AllenaMente',
          type: 'item',
          url: '/allenamente',
          breadcrumbs: false
        },
        {
          id: 'alfabeto',
          title: 'Alfabeto',
          type: 'item',
          url: '/alfabeto',
          breadcrumbs: false
        }
      ],
    },
    {
      id: 'recordingroom',
      title: 'Sala di registrazione',
      type: 'item',
      url: '/sala-di-registrazione',
      icon: MicIcon,
      breadcrumbs: false
    },
  ]
}

export default training;
