
import { IconBrandChrome, IconHelp, IconBooks } from '@tabler/icons';
import QueryStatsIcon from '@mui/icons-material/QueryStats';
import EmojiObjectsIcon from '@mui/icons-material/EmojiObjects';
import TextRotationNoneIcon from '@mui/icons-material/TextRotationNone';
import GradeIcon from '@mui/icons-material/Grade';
import LyricsIcon from '@mui/icons-material/Lyrics';

// ==============================|| DASHBOARD MENU ITEMS ||============================== //
// constant
const icons = { IconBrandChrome, IconHelp, IconBooks, EmojiObjectsIcon, QueryStatsIcon, TextRotationNoneIcon, GradeIcon };

const extra = {
  id: 'extra',
  type: 'group',
  title: 'Extra',
  children: [
    {
      id: 'autodiction',
      title: 'Dizionatura',
      type: 'item',
      url: '/autodiction',
      icon: icons.TextRotationNoneIcon,
      breadcrumbs: false
    },
    {
      id: 'canzoni',
      title: 'Canzoni',
      type: 'item',
      url: '/canzoni',
      icon: LyricsIcon,
      breadcrumbs: false
    }
  ]
}

export default extra;
