import * as React from 'react';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';
import { ListItem } from '@mui/material';
import { useTheme } from '@mui/material';

/*{
    icon
    name
    value
    isEditable
    type
}
onFieldEdited
*/

export default function PropertiesViewer({itemList, onItemSelected}) {
    
    const theme = useTheme();

    /*const handleItemEdited = (item) => {
        onItemEdited(item);
    }*/

    const handleItemselected = (item) => {
        onItemSelected(item);
    }


  return (
    <List 
    sx={{
        width: '100%',
        bgcolor: 'background.paper',
        position: 'relative',
        overflow: 'auto',
        maxHeight: 300,
        '& ul': { padding: 0 },
      }}>
   {
    itemList.map(el =>
        <>
            <ListItem
                alignItems="flex-start" role={undefined} onClick={ () => handleItemselected(el)} dense>
            <ListItemAvatar>
                <Avatar sx={{ bgcolor: theme.palette.primary.light}}>
                    {el.icon}
                </Avatar>
            </ListItemAvatar>
            <ListItemText
            primary={el.name}
            secondary={
                <React.Fragment>
                <Typography
                    sx={{ display: 'inline' }}
                    component="span"
                    variant="body2"
                    color="grey.500"
                >
                     {el.value}
                </Typography>
                {""}
                </React.Fragment>
            }
            />
        </ListItem>
        <Divider variant="inset" component="li" />
        </>
        )
   }
    </List>
  );
}
