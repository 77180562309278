import {fetch, post, doDeleteFromCompleteUrl} from "./ortoepiaWebClient";
import CONSTANTS from "./dictionUtils/constants";

export async function feedback(type, message, rating) {
  const date = new Date();
  const doc = {
    date,
    type,
    message,
    rating
  }
  return post('user/feedback', doc);
}

export async function dictionText(text) {

let placeholderText = text;
const wordsMap = {};
const regex = /[0-9A-Za-zìÈéòàìèù-]+/gm;
//TODO: gestire quando il simbolo '-' è usato per andare a capo (provare a rimuoverlo)
let m;
const PLACEHOLDERS = new Set();
const PLACEHOLDERS_MAP = {};
let placeholderIdx = 0;

const finalArray = [];
let prevLastIndex= -1;

while ((m = regex.exec(text)) !== null) {
    // This is necessary to avoid infinite loops with zero-width matches
    if (m.index === regex.lastIndex) {
        regex.lastIndex++;
    }
    m.forEach((match) => {
        const startIdx = regex.lastIndex - match.length;
        if (!PLACEHOLDERS.has(match)) {
          PLACEHOLDERS.add(match)
          PLACEHOLDERS_MAP[match] =  `{${++placeholderIdx}}`
        }
        //aggiungo quello che c'era prima
        if (prevLastIndex !== -1) {
          const interval = text.substring(prevLastIndex, startIdx)
          const intervalTrimmed = interval.replace(/^ +| +$/gm, '');

          // TODO Rename in handleInterval
          handleNewLines(intervalTrimmed, finalArray);
      }
        finalArray.push({
          spell: match,
          isReal: true
        })
        prevLastIndex = regex.lastIndex;
        ////console.log(`Found match: ${match} at ${startIdx} to ${regex.lastIndex}`);
      
    });
  }
      // gestione eventuale ultimo segmento, dall'ultimo \n alla fine di str
      const lastSegment = text.substring(prevLastIndex);
      if (lastSegment.length > 0) {
        finalArray.push({
          spell: lastSegment,
          isReal: false
        })
      }

      // dal set di placeholder ricavo una lista che ordino in base alla lunghezza
      const placeholdersArray = [...PLACEHOLDERS].sort((a, b) => b.length - a.length)
      // dal più lungo al più corto sostituisco il placeholder
      placeholdersArray.forEach(ph => {
        placeholderText = placeholderText.replaceAll(ph, PLACEHOLDERS_MAP[ph]);
      })
    
      //console.log(`PLACEHOLDER TEXT: ${placeholderText}` );

      // TEST: rimetto a posto il testo
  /*    let recreatedText = result.placeholderText;
      Object.keys(PLACEHOLDERS_MAP).forEach(key => {
        recreatedText = recreatedText.replaceAll(PLACEHOLDERS_MAP[key], key);
      });
      //console.log(`REINTEGRATED TEXT: ${recreatedText}` );
      //console.log(`PLACEHOLDER ARRAY: `, PLACEHOLDERS );
      //console.log(`PLACEHOLDERS_MAP: `, PLACEHOLDERS_MAP );*/

  // per ogni parola il PLACEHOLDERS guardo in cache per vedere se ce l'ho già
  const toAskBe = [];
      PLACEHOLDERS.forEach(match => {
        const cacheEl = CACHE[match];
        if (cacheEl) { // usa questo
          wordsMap[match] = CACHE[match];
        }
        else if (isInteresting(match)){ //aggiungo alle parole da chiedere al BE
          toAskBe.push(match);
        }
      });
      
      //console.log("Queste sono le parole da chiedere al backend: ", toAskBe);

      //  TODO eventualmente spezzo richieste in chunk
      //  TODO: ok, a valle ci sarà qualcosa che mi converte i caratteri tra un placeholder e l'altro in una word farlocca
      //  TODO: gestione maiuscole, ma la posso fare a BE

  return post('vocabulary/diction', {words: toAskBe}).then(({dictioned}) => {
    Object.keys(dictioned).forEach(k => { // Aggiorno cache
      CACHE[k] = dictioned[k];
      // Aggiorno mappa result, per adesso ignoro info aggiuntive su sorgente
      wordsMap[k] = dictioned[k];
    });

    const result = finalArray.map(el => {
      if (wordsMap[el.spell]) {
        return {
          ...wordsMap[el.spell],
          isReal: el.isReal
      }
    }
      else {
        // parola farlocca
        return {
          "spell": el.spell,
          "isReal": el.isReal
      }
    }});

    //console.log("FINALARRAY: ", result);
    return result;
  });
}

function isNumeric(str) {
  if (typeof str != "string") return false // we only process strings!  
  return !isNaN(str) && // use type coercion to parse the _entirety_ of the string (`parseFloat` alone does not do this)...
         !isNaN(parseFloat(str)) // ...and ensure strings of whitespace fail
}

const isInteresting = (match) => {
  //restituisco true se ha una e|o|z|s
  if (isNumeric(match)) return true;
  for (let ch of match) {
    if (CONSTANTS.INTERESTING_CHARS.includes(ch)) {
      return true;
    }
  }
  //console.log(" -> " + match + " was not interesting")
  return false;
}


export async function updateStats(quizResult) {
  return post('user/quizResult', quizResult);
}

export async function readStats() {
  return fetch('user');
}

export async function readStatsTs(username, aggr) {
  return fetch('stats?aggr=' + aggr);
}

// METODO CHE RESTITUISCI TUTTI GLI USERDATA
export async function datiUtente(username) { 
  throw error("Not implementes", username)
  }

 const handleNewLines = (str, finalArray) => {
     // Cerco i newline con regex
    // gestisco come funzione principale
     // gli interval li setto come word uniche in array

     const regex = /\r?\n/gm;
     let m;
     let prevLastIndex= -1;
     while ((m = regex.exec(str)) !== null) {
         // This is necessary to avoid infinite loops with zero-width matches
         if (m.index === regex.lastIndex) {
             regex.lastIndex++;
         }
         m.forEach((match) => { // for each newline add a \n to the array
            const startIdx = regex.lastIndex - match.length;
            //console.log(`Found match newline: ${match}`);
            let segment = str.substring(0, startIdx);

            if (prevLastIndex !== -1) {
               segment = str.substring(prevLastIndex, startIdx);
            }
              if (segment.length > 0) {
                finalArray.push({
                  spell: segment,
                  isReal: false
                });
              }
              // Add newline
              finalArray.push({
                spell: '\n',
                isReal: false
              });
              prevLastIndex = regex.lastIndex;
            });
     }
    // gestione eventuale ultimo segmento, dall'ultimo \n alla fine di str
    const lastSegment = str.substring(prevLastIndex);
    if (lastSegment.length > 0) {
      finalArray.push({
        spell: lastSegment,
        isReal: false
      })
    }

    //console.log("Esco da handleNewline: ", finalArray);
}

export const saveRecording = (body) => {
 return post('recording', body);
}

export async function getAllRecordings(dubbingRef) {
  return fetch('user/recordings?dubbingRef=' + dubbingRef);
}

export async function deleteRecording(url) {
  return doDeleteFromCompleteUrl(url);
}

export async function getDubbingResources() {
  return fetch('static/recording/dubbings');
}

export async function getAudiobookResources() {
  return fetch('static/recording/books');
}

export async function getAdsResources() {
  return fetch('static/recording/ads');
}

export async function getTongueTwistersResources() {
  return fetch('static/recording/tonguetwisters');
}

export async function updateTrackStartPosition(id, newStartPos) {
    return post('recording/startPosition/' + id + '?newStartPosition=' + newStartPos);
}

