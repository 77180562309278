import { Box } from '@mui/material';
import MainCard from 'ui-component/cards/MainCard';
import { useEffect, useState } from 'react';
import {useKeycloak} from '@react-keycloak/web';

// ==============================|| MAIN LAYOUT ||============================== //

const LoginFailed = () => {
    const { keycloak } = useKeycloak();
    const [logoutTimer, setLogoutTimer] = useState(-1);

    useEffect(()=> {
        if (logoutTimer === 0) {
           keycloak.logout();
        }
    }, [logoutTimer])

    useEffect(() => {
        //console.log("Set interval")
        const interval = setInterval(() => {
            //console.log("Set interval timeout " + logoutTimer)
            setLogoutTimer(prev => prev - 1);
        }, 1000);
        setLogoutTimer(5);
        return () => clearInterval(interval);
    }, [keycloak])

  return (
    <MainCard border={false} elevation={16} content={false} boxShadow>
        <Box p={3}>
            <p>
                Il login non è andato a buon fine, ti ricordiamo che è necessario accettare i termini sulla riservatezza.
            </p>
            <p>
                Logout in corso... {logoutTimer > 0 ? logoutTimer : null}
            </p>
        </Box>
    </MainCard>
  );
};

export default LoginFailed;
