import { lazy } from 'react';

// project imports
import MainLayout from 'layout/MainLayout';
import Loadable from 'ui-component/Loadable';

// dashboard routing
const DashboardUserStats = Loadable(lazy(() => import('views/dashboard/UserStats')));

// utilities routing
const UtilsTypography = Loadable(lazy(() => import('views/utilities/Typography')));
const UtilsColor = Loadable(lazy(() => import('views/utilities/Color')));
const UtilsShadow = Loadable(lazy(() => import('views/utilities/Shadow')));
const UtilsMaterialIcons = Loadable(lazy(() => import('views/utilities/MaterialIcons')));
const UtilsTablerIcons = Loadable(lazy(() => import('views/utilities/TablerIcons')));

// sample page routing
const SamplePage = Loadable(lazy(() => import('views/sample-page')));

// Rules
const RulesPage = Loadable(lazy(() => import('views/rules')));
const EAPerta = Loadable(lazy(() => import('views/rules/eaperta')));
const EChiusa = Loadable(lazy(() => import('views/rules/echiusa')));
const OAperta = Loadable(lazy(() => import('views/rules/oaperta')));
const OChiusa = Loadable(lazy(() => import('views/rules/ochiusa')));

const SSorda = Loadable(lazy(() => import('views/rules/ssorda')));
const SSonora = Loadable(lazy(() => import('views/rules/ssonora')));
const ZSorda = Loadable(lazy(() => import('views/rules/zsorda')));
const ZSonora = Loadable(lazy(() => import('views/rules/zsonora')));

// Quiz
const QuizPage = Loadable(lazy(() => import('views/quiz')));
const QuizCustom = Loadable(lazy(() => import('views/quiz/custom')));
const QuizGroup = Loadable(lazy(() => import('views/quiz/index')));

// Extra
const ExtraPage = Loadable(lazy(() => import('views/extra')));
const SongsPage = Loadable(lazy(() => import('views/extra/songs')));

// Home
const HomePage = Loadable(lazy(() => import('views/home')));

//Feedback
const FeedbackPage = Loadable(lazy(() => import('views/feedback')));

// Diction
const DictionPage = Loadable(lazy(() => import('views/diction')));

// RecordingRoom
const RecordingRoomIndexPage = Loadable(lazy(() => import('views/newRecordingRoom')));
const RecordingRoomPage = Loadable(lazy(() => import('views/newRecordingRoom/studio/studioManager')));
/*const RecordingRoomAudiobooksPage = Loadable(lazy(() => import('views/recordingroom/audiobooks')));
 const RecordingRoomDubbingPage = Loadable(lazy(() => import('views/recordingroom/dubbing/studio/studioManager')));
*/

// Percorso
const HikingPage = Loadable(lazy(() => import('views/hiking')));

// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = {
  path: '/',
  element: <MainLayout />,
  children: [
    {
      path: '/',
      element: <HomePage />
    },
    {
      path: 'percorso',
      element: <HikingPage />
    },
    {
      path: 'progressi',
      element: <DashboardUserStats />
    },
    {
      path: 'utils',
      children: [
        {
          path: 'util-typography',
          element: <UtilsTypography />
        }
      ]
    },
    {
      path: 'utils',
      children: [
        {
          path: 'util-color',
          element: <UtilsColor />
        }
      ]
    },
    {
      path: 'utils',
      children: [
        {
          path: 'util-shadow',
          element: <UtilsShadow />
        }
      ]
    },
    {
      path: 'icons',
      children: [
        {
          path: 'tabler-icons',
          element: <UtilsTablerIcons />
        }
      ]
    },
    {
      path: 'icons',
      children: [
        {
          path: 'material-icons',
          element: <UtilsMaterialIcons />
        }
      ]
    },
    {
      path: 'sample-page',
      element: <SamplePage />
    },
    {
      path: 'feedback',
      element: <FeedbackPage />
    },
    {
      path: 'home',
      element: <HomePage />
    },
    {
      path: '/',
      children: [
        {
          path: 'intro',
          element: <RulesPage />
        },
        {
          path: 'e-aperta',
          element: <EAPerta />
        },
        {
          path: 'e-chiusa',
          element: <EChiusa />
        },
        {
          path: 'o-aperta',
          element: <OAperta />
        },
        {
          path: 'o-chiusa',
          element: <OChiusa />
        },
        {
          path: 's-sorda',
          element: <SSorda />
        },
        {
          path: 's-sonora',
          element: <SSonora />
        },
        {
          path: 'z-sorda',
          element: <ZSorda/>
        },
        {
          path: 'z-sonora',
          element: <ZSonora />
        }
      ]
    },
    {
      path: '/',
      children: [
        {
          path: 'intro',
          element: <QuizPage />
        },
        {
          path: 'custom',
          element: <QuizCustom />
        },
        {
          path: 'alfabeto',
          element: <QuizGroup type={'database'}/>
        },
        {
          path: 'allenamente',
          element: <QuizGroup type={'groups'}/>
        }
      ]
    },
    {
        path: 'autodiction',
        element: <DictionPage />
    },
    {
      path: '/',
      children: [
        {
          path: 'sala-di-registrazione',
          element: <RecordingRoomIndexPage />
        },
            {
              path: 'sala-di-registrazione/:type/:id',
              element: <RecordingRoomPage />
            }
      ]
    },
    {
      path: '/',
      children: [
        {
          path: 'intro',
          element: <ExtraPage />
        },
        {
          path: 'canzoni',
          element: <SongsPage />
        }
      ]
  }
  ]
};

export default MainRoutes;
