/*  Espone i metodi per ricavare i dati dell'utente relativi a statistiche etc... 
    Espona anche i metodi per ottenere i dati da keycloak, prendendo in input l'oggetto keycloak
*/
import { datiUtente } from 'core/DbAdapter';

export const userService = {
  getUserData: async (username) => {
    return datiUtente(username).then((res) => {
      const finalResult = { ...res };
      ////console.log('Arrivati i dati utente: ', finalResult);
      return finalResult;
    });
  },
  getKeycloakUserData: (keycloak) => {
    if (!keycloak || !keycloak.authenticated) {
      return false;
    }
    // //console.log("KEYCLOAK DATA: ", keycloak)
    const kData = keycloak.tokenParsed;
    const _loginDate = new Date(0);
    _loginDate.setUTCSeconds(kData.auth_time);
    const result = {
      name: kData.given_name,
      lastLogin: _loginDate,
      email: kData.email,
      emailVerified: kData.email_verified,
      surname: kData.family_name,
      username: kData.preferred_username,
      isPremium: kData.role?.includes("premium-user"),
    };
    return result;
  }
};
