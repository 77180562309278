import axios from 'axios';

/*
    Questo è il client per il servizio htt2db, sarà da esternalizzare come libreria // TODO
 */

export const config = {
  origin: window.location.origin,
  method: 'get',
  url: 'api/',
  timeout: 30000,
  responseEncoding: 'utf8',
  responseType: 'json',
  maxContentLength: 100000000,
  maxBodyLength: 1000000000,
  data: {},
  headers: { Authentication: 'Bearer ' }
};

// GET POST

export async function fetch(path) {
  const request = { ...config, url: config.origin + '/' + config.url + path, timeout: 25000 };
  return performFetch(request);
}

export async function post(path, body) {
  const request = { ...config, method: 'POST', data: body, url: config.origin + '/' + config.url + path, timeout: 25000 };
  return performFetch(request);
}

export async function doDeleteFromCompleteUrl(completeUrl) {
  const request = { ...config, method: 'DELETE', url: completeUrl };
  return performFetch(request);
}

async function performFetch(config) {
  const authHeader = config.headers.Authentication + localStorage.getItem("token");
  //config.headers.Authentication = authHeader;
  return new Promise((resolve, _error) => {
    try {
      axios({...config, headers: {...config.headers, "Authorization": authHeader}})
        .then(function (response) {
          const decodedObj = response.data;
          resolve(decodedObj);
        })
        .catch(function (error) {
          _error(error);
          resolve(null);
        });
    } catch (err) {
      console.error('Questo è un erroraccio');
    }
  });
}
