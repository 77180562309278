import * as React from 'react';
import { Button, Grid, Dialog, DialogActions, DialogTitle, DialogContent, Box, Typography } from '@mui/material';
import { useEffect } from 'react';
import { useKeycloak } from '@react-keycloak/web';
import { userService } from 'services/userService';
import PropertiesViewer from 'ui-component/PropertiesViewer';
import PersonIcon from '@mui/icons-material/Person';
import AlternateEmailIcon from '@mui/icons-material/AlternateEmail';

import VerifiedUserIcon from '@mui/icons-material/VerifiedUser';
import GppMaybeIcon from '@mui/icons-material/GppMaybe';
import { useState } from 'react';
import LoginIcon from '@mui/icons-material/Login';

import WorkspacePremiumIcon from '@mui/icons-material/WorkspacePremium';
// ==============================|| FEEDBACK PAGE ||============================== //

const UserInfo = ({open, onClose}) => {

  const { initialized, keycloak } = useKeycloak();
  const [dataModel, setDataModel] = useState({});

  useEffect(() => {
    if (keycloak && keycloak.authenticated) {
      const authData = userService.getKeycloakUserData(keycloak);

      const verifiedMailIcon = <VerifiedUserIcon></VerifiedUserIcon>
      const unverifiedMailIcon = <GppMaybeIcon></GppMaybeIcon>
      const personIcon = <PersonIcon></PersonIcon>
      const emailIcon = <AlternateEmailIcon></AlternateEmailIcon>
      const lastLoginIcon = <LoginIcon></LoginIcon>
      const premiumIcon = <WorkspacePremiumIcon></WorkspacePremiumIcon>

      const formattedData = {
            username: {
                icon: personIcon,
                name: 'Username',
                value: authData.username,
                },
            name: {
              icon: personIcon,
                name: 'Nome',
                value: authData.name
                },
            surname: {
              icon: personIcon,
              name: 'Cognome',
              value: authData.surname
                },
            email: {
              icon: emailIcon,
              name: 'Email',
              value: authData.email || 'Assente'
            },
            verifiedMail: {
              icon: authData.emailVerified ? verifiedMailIcon : unverifiedMailIcon,
              name: 'Email verificata',
              value: authData.emailVerified ? 'Sì' : 'No'
            },
            premium: {
              icon: premiumIcon,
              name: 'Utente Premium',
              value: authData.isPremium  ? 'Sì' : 'No'
            },
            lastLogin: {
              icon: lastLoginIcon,
              name: 'Ultimo login',
              value: authData.lastLogin.toLocaleDateString("it-IT")
          }
        }
        setDataModel(formattedData);
    }
}, [initialized, keycloak])

    
  return (<Dialog
        fullWidth={true}
        maxWidth={'sm'}
        open={open}
      >
        <DialogTitle>
          <Typography variant='h3'>
            Dati utente
          </Typography>
        </DialogTitle>
        <DialogContent>
      <Box sx={{ p: 0 }}>
          <Typography variant='h5' mb={1}>Dati dell&#39; utente {dataModel.username?.value || '' } </Typography>
          <Typography variant='p'>
            <PropertiesViewer itemList={Object.values(dataModel)}></PropertiesViewer>
          </Typography>
      </Box>

        <Box sx={{ flexGrow: 1 }}>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={6}>
        </Grid>
        <Grid item xs={12} sm={6}>
        </Grid>
      </Grid>
    </Box>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" color="info"  onClick={() => onClose()}>Chiudi</Button>
        </DialogActions>
      </Dialog>
  );
}

export default UserInfo;
