import { useSelector } from 'react-redux';

import { ThemeProvider } from '@mui/material/styles';
import { CssBaseline, StyledEngineProvider } from '@mui/material';
import { ReactKeycloakProvider } from "@react-keycloak/web";


// routing
import Routes from 'routes';

// defaultTheme
import themes from 'themes';

// project imports
import NavigationScroll from 'layout/NavigationScroll';

import keycloak from 'Authentication/myKeycloak';

// ==============================|| APP ||============================== //

const App = () => {

  const saveToken = (token) => {
    Object.keys(token).forEach(k => {
      localStorage.setItem(k, token[k]);
    })
  }
  
  const logEvent = () => {
   ////console.log("Auth event:", event)
  }

  const logError = () => {
    ////console.log("Auth error:", event)
   }

  const customization = useSelector((state) => state.customization);

  return (
    <ReactKeycloakProvider authClient={keycloak}
      onTokens={saveToken}
      onEvent={logEvent}
      onError={logError}
      initOptions={
        {
          onLoad: 'login-required',
          pkceMethod: 'S256'
          }}>
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={themes(customization)}>
        <CssBaseline />
        <NavigationScroll>
          <Routes />
        </NavigationScroll>
      </ThemeProvider>
    </StyledEngineProvider>
    </ReactKeycloakProvider>
  );
};

export default App;
