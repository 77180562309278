import * as React from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { useState } from 'react';
import InsertCommentIcon from '@mui/icons-material/InsertComment';
import BugReportIcon from '@mui/icons-material/BugReport';
import { Button, Grid, Dialog, DialogActions, DialogTitle, DialogContent, Box, Typography, Rating, Stack } from '@mui/material';
import { useEffect } from 'react';
import { feedback } from '../../core/DbAdapter';
import { useKeycloak } from '@react-keycloak/web';
import { userService } from '../../services/userService';
import Notification from 'ui-component/Notification';

// ==============================|| FEEDBACK PAGE ||============================== //

const Feedback = ({ open, onClose }) => {
  const { initialized, keycloak } = useKeycloak();

  const TYPES = ['comment', 'bug'];

  const [typeIdx, setTypeIdx] = useState(0);
  const [rating, setRating] = useState(0);
  const [message, setMessage] = useState('');
  const [userData, setUserData] = useState(false);
  const [messageHint, setMessageHint] = useState('');
  const [notification, setNotification] = useState({
    message: '',
    type: '',
    triggerOpen: false
  });

  useEffect(() => {
    if (initialized && keycloak) {
      const data = userService.getKeycloakUserData(keycloak);
      setUserData(data);
    }
  }, [keycloak]);

  const handleChange = (event, newValue) => {
    setTypeIdx(newValue);
    setMessage('')
  };

  const handleNotificationClose = () => {
    setNotification({triggerOpen: false, message: ''});
  }

  const handleFocus = (event) => {
    let current = event.target.value;
    if (current === messageHint) {
      setMessageHint('')
    }
  }

  const handleChangeMessage = (event) => {
    setMessage(event.target.value)
  };

  const send = () => {
    feedback(TYPES[typeIdx], message, rating).then(() => {
      setNotification({triggerOpen: true, type: 'success', message: "Feedback ricevuto. Grazie!"});
      setMessage('');
      setRating(0);
    })
    .catch(err => {
      console.error("feedback error: ", err);
      setNotification({triggerOpen: true, type: 'error', message: "Feedback non inviato correttamente :("});
    });
  };

  useEffect(() => {
    const message = typeIdx === 0 ? 'Scrivi qui il tuo commento' : 'Descrivi qui il bug e se possibile come riprodurlo';
    setMessageHint(message);
  }, [typeIdx]);

  
  return (
    <Dialog fullWidth={true} maxWidth={'sm'} open={open}>
      <DialogTitle>
        <Typography variant='h3'>
          Feedback
        </Typography>
      </DialogTitle>
      <DialogContent>
        <Notification 
          openTrigger={notification.triggerOpen} 
          message={notification.message} 
          type={notification.type}
          onClose={handleNotificationClose}></Notification>

        <Box sx={{ m: 0}}>
          <Typography variant="p">
            <Stack spacing={2}>
              <Tabs value={typeIdx} onChange={handleChange} aria-label="icon label tabs example">
                <Tab icon={<InsertCommentIcon />} label="Lascia un commento" />
                <Tab icon={<BugReportIcon />} label="Segnala un bug" />
              </Tabs>
              <textarea
                value={message}
                defaultValue={messageHint}
                onFocus={handleFocus}
                onChange={handleChangeMessage}>
              </textarea>
              <Box display={'flex'} justifyContent={'space-around'} alignItems={'center'}>
                <h4>Dai un voto all&#39; app</h4>
                  <Rating name="half-rating" precision={0.5} 
                    value={rating}
                    onChange={(event, newValue) => {
                      setRating(newValue);
                    }}
                  />
              </Box>
            </Stack>
          </Typography>
        </Box>

        <Box sx={{ flexGrow: 1 }}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6}></Grid>
            <Grid item xs={12} sm={6}></Grid>
          </Grid>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button variant="contained" color="info" onClick={() => onClose()}>
          Chiudi
        </Button>
        <Button variant="contained" disabled={!userData || message === ''} onClick={() => send()}>
          Invia
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default Feedback;
