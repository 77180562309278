import * as React from 'react';
import Snackbar from '@mui/material/Snackbar';
import { useEffect } from 'react';
import MuiAlert from '@mui/material/Alert';

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });


export const Notification = ({openTrigger, message, onClose, type}) => {
  const [state, setState] = React.useState({
    vertical: 'top',
    horizontal: 'right',
  });
  const { vertical, horizontal, open } = state;

  useEffect(() => {
    setState({...state, open: openTrigger})
  }, [openTrigger])

  const handleClose = () => {
    setState({ ...state, open: false });
    onClose();
  };

  return (
      <Snackbar
        autoHideDuration={4000}
        anchorOrigin={{ vertical, horizontal }}
        open={open}
        onClose={handleClose}
        key={vertical + horizontal}
      >
        <Alert onClose={handleClose} severity={type} sx={{ width: '100%' }}>
            {message}
        </Alert>
    </Snackbar>
  );
}

export default Notification;