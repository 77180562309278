
import extra from './extra';
import training from './training';

// ==============================|| MENU ITEMS ||============================== //

const menuItems = {
  items: [training, extra]
};

export default menuItems;
